<template>
  <FastOrdersEditAdmin
      :FO="FO"
      @saveFastOrders="saveFastOrders"
      @createLabelsForFastOrders="createLabelsForFastOrders"
  />
</template>

<script>
  import {FastOrders} from "../../models/FastOrders";
  import {fastOrdersMixin} from "../../../../../mixins/fastOrdersMixins/fastOrdersMixin";
  import FastOrdersEditAdmin from "./FastOrdersEditAdmin/FastOrdersEditAdmin";

  export default {
    name: "FastOrdersEdit",

    components: {
      FastOrdersEditAdmin,
    },

    mixins: [
      fastOrdersMixin
    ],

    data() {
      return {
        FO: new FastOrders(),
      }
    },

    created() {
      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      this.initFastOrdersEdit()
    },

    methods: {
      initFastOrdersEdit() {

        this.$store.dispatch('getFastOrders', this.$route.params.id).then(() => {
          this.FO.setFastOrdersItem(this.$store.getters.getFastOrdersItem)
        }).catch(error => this.createErrorLog(error))

      },

    }
  }
</script>

<style scoped>

</style>