<template>
  <ExpressFastOrdersEditAdmin
      v-if="loaded"
      :EFO="EFO"
      @saveExpressFastOrders="saveExpressFastOrders"
      @exportExpressFastOrders="exportExpressFastOrders"
  />
</template>

<script>
  import {ExpressFastOrders} from "../../models/ExpressFastOrders";
  import {expressFastOrdersMixin} from "../../../../../mixins/expressFastOrdersMixins/expressFastOrdersMixin";
  import ExpressFastOrdersEditAdmin from "./ExpressFastOrdersEditAdmin/ExpressFastOrdersEditAdmin";

  export default {
    name: "ExpressFastOrdersEdit",

    components: {
      ExpressFastOrdersEditAdmin,
    },

    mixins: [
      expressFastOrdersMixin
    ],

    data() {
      return {
        EFO: new ExpressFastOrders(),
        loaded: false,
      }
    },

    created() {
      // if(this.downloadPermissions){
      //   if(!this.checkPermission()) return
      // }

      this.initExpressFastOrdersEdit()
    },

    methods: {
      initExpressFastOrdersEdit() {

        this.$store.dispatch('getExpressFastOrders', this.$route.params.id).then(() => {
          this.EFO.setExpressFastOrdersItem(this.$store.getters.getExpressFastOrdersItem?.order_express_fast_order)
          this.loaded = true
        }).catch(error => this.createErrorLog(error))

      },

    }
  }
</script>

<style scoped>

</style>