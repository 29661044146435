<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'common_backTo',
                  ])"></div>
                <router-link :to="$store.getters.GET_PATHS.reportsExpressFastOrders" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('common_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'menu_expressFastOrders',
                  ])"></div>
              {{$t('menu_expressFastOrders.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2">

            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

<!--            <div class="order-create__row custom-row">-->
<!--              <div class="custom-col custom-col&#45;&#45;66 custom-col&#45;&#45;md-100"-->
<!--                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate([-->
<!--                  'common_comment',-->
<!--                  ])"></div>-->
<!--                <TextareaDefault-->
<!--                    :label="$t('common_comment.localization_value.value')"-->
<!--                    v-model="FO.data.comment"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->

            <ScannerBlock
                :EFO="EFO"
                @updateOrder="(expressId, takePhoto) => $emit('saveExpressFastOrders', true, expressId, true, takePhoto)"
            />

          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_cancel',
                  ])"></div>
              <router-link :to="$store.getters.GET_PATHS.reportsExpressFastOrders"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_save',
                  ])"></div>

              <router-link
                  class="order-create__footer-btn-i"
                  target="_blank"
                  :to="$store.getters.GET_PATHS.generatePdfFile + '/get-labels-express-fast-order?id=' + EFO.data.id">
                <MainButton
                    class="order-create__footer-btn-i secondary"
                    :value="$t('fastOrders_getLabels.localization_value.value')"
                    v-bind:class="{'disabled-btn' : $store.getters.getExpressFastOrdersBtn}"
                />
              </router-link>


              <MainButton
                  class="order-create__footer-btn-i secondary"
                  :value="$t('common_export.localization_value.value')"
                  v-bind:class="{'disabled-btn' : $store.getters.getExpressFastOrdersBtn}"
                  @click.native="$emit('exportExpressFastOrders')"
              />

              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_save.localization_value.value')"
                  v-bind:class="{'disabled-btn' : $store.getters.getExpressFastOrdersBtn}"
                  @click.native="$emit('saveExpressFastOrders', true)"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  // import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import ScannerBlock from "../../chunks/ScannerBlock";

  export default {
    name: "ExpressFastOrdersEditAdmin",
    components: {
      // TextareaDefault,
      ScannerBlock,
      MainButton,
      CardLeftBlock,
      CardRightBlock,
      LinkBack,
    },

    mixins: [mixinDetictingMobile],

    props: {
      EFO: Object,
    },

    data(){
      return{

      }
    },
  }
</script>

<style scoped>
  .checkbox-scoped{
    margin-right: -5px;
  }

  td:nth-child(2){
    font-size: 12px;
    line-height: 14px;
  }
</style>
